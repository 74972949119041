$color-black: #111111;
$color-hover-background: #E2E2E2;
$color-pin-red: #E60023;
$color-vip-red: #FF4D4D;
$color-vip-black: #333;
$color-gray: #767676;
$color-gray1: #EFEFEF;
$color-gray2: #AAA;

.cl{  }
.cl:after{
  content: ""; display: block;clear: both;
}
.ani{
  transition: all 0.5s ease;
}
$color-black:#2C3E50;
$color-red:#FE4365;
$colorDzRed:#FF4D4D;
$a_link:#e67e22;
$a_active:$color-red;
$font_color:#4A4A4A;
$basicColorRed:$color-red;
$headerbg:#2C3E50;

$main_color:#ff4d4d;
// 文字用色
$color-h1:#303030; // 主要,一级文字
$color-h2:#767676;// 次级文字
$color-desc:#AAAAAA; // 辅助说明文字
$color-white:#ffffff; // 反白文字
$color-title: #b4b4b4; //标题
$color-text: #333333; // 主要文字
$color-yellow: #FFAA00; //
$color-blue: #5BB6FF; //
$color-nav-normal: #515151;


//主要色
$color-theme:#FF4D4D; // 主题色 深红
$color-bgc:#f8f8f8; // 背景 输入框等
$color-theme-datu: #E10600; // 主题色，大兔深红

//辅助色
$color-sea:#fafafa;// 聚合搜索条目栏背景
$color-btncli:rgba(255,77,77,.5); // 按钮点击态
$color-nav:rgba(255,77,77,.15); // 引导页,找大图
$color-pop:#eeeeee; // 部分弹框背景
$color-br:#dedede ; // 分割线

//导航2020版
$color-header-font: #666666; //导航栏细条文字颜色



.font{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}
.oneline{
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
}
.svgGray{
  fill:#767676;
}
.svgRed{
  fill: #e60023;
}
