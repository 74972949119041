@charset "UTF-8";

//@font-face {
//    font-family: "SourceHanSansSC-Regular";
//    src: url(https://xcx.bigbigwork.com/p/fonts/SourceHanSansSC-Regular.ttf) format("truetype");
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: "SourceHanSansSC-Medium";
//    src: url(https://xcx.bigbigwork.com/p/fonts/SourceHanSansSC-Medium.ttf) format("truetype");
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: "SourceHanSansSC-Bold";
//    src: url(https://xcx.bigbigwork.com/p/fonts/SourceHanSansSC-Bold.ttf) format("truetype");
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: "TsangerJinKai02-W01";
//    src: url(https://xcx.bigbigwork.com/p/fonts/TsangerJinKai02-W01.ttf) format("truetype");
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//@font-face {
//    font-family: "TsangerJinKai02-W03";
//    src: url(https://xcx.bigbigwork.com/p/fonts/TsangerJinKai02-W03.ttf) format("truetype");
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//.font-regular {
//    font-family: "SourceHanSansSC-Regular", "Microsoft Yahei", "微软雅黑", sans-serif;
//    font-weight: normal;
//    font-style: normal;
//}
//
//.font-medium {
//    font-family: "SourceHanSansCN-Medium", "SourceHanSansSC-Medium", "Microsoft Yahei", "微软雅黑", sans-serif;
//    font-weight: normal;
//    font-style: normal;
//}
//
//.font-bold {
//    font-family: "SourceHanSansSC-Bold", "Microsoft Yahei", "微软雅黑", sans-serif;
//    font-weight: normal;
//    font-style: normal;
//}
.font-regular {
    font-weight: normal;
    font-style: normal;
}

.font-medium {
    font-weight: normal;
    font-style: normal;
}

.font-bold {
    font-weight: bold;
    font-style: normal;
}
