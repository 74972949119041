@charset "UTF-8";
.cl:after {
  content: "";
  display: block;
  clear: both; }

.ani {
  transition: all 0.5s ease; }

.font {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important; }

.oneline {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis; }

.svgGray {
  fill: #767676; }

.svgRed {
  fill: #e60023; }

.font-regular, .normal2-text {
  font-weight: normal;
  font-style: normal; }

.font-medium {
  font-weight: normal;
  font-style: normal; }

.font-bold, .normal, .normal2, .textOnly {
  font-weight: bold;
  font-style: normal; }

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 none;
  padding: 0;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center; }
  .button span {
    line-height: 0; }
  .button:active, .button:focus {
    outline: 0 none; }
  .button[disable]:active:before, .button[disable]:focus:before, .button[disable]:hover:before {
    display: none; }
  .button[disable]:after {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
    pointer-events: none; }
  .button:active:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
    pointer-events: none; }

.full {
  flex: 1; }

.content {
  flex-shrink: 0;
  flex-grow: 0; }

.normal {
  height: 76px;
  font-size: 32px; }

.normal2 {
  height: 90px;
  font-size: 32px; }

.normal2-text {
  height: 90px;
  font-size: 28px; }

.bbw {
  height: 64px;
  padding: 18px 20px;
  line-height: 64px;
  font-size: 28px; }
  .bbw svg, .bbw img {
    margin-right: 8px;
    width: 24px;
    height: 24px; }

.bbwIconOnly {
  width: 64px;
  height: 64px;
  padding: 18px 20px;
  line-height: 64px;
  font-size: 28px;
  padding: 0; }
  .bbwIconOnly :global(.icon), .bbwIconOnly svg, .bbwIconOnly img {
    margin-right: 0px;
    max-width: 40px;
    max-height: 40px; }

.primary {
  background-color: #FF4D4D;
  color: #fff; }
  .primary svg, .primary img, .primary .icon {
    fill: #fff; }

.textOnly {
  color: #AAA;
  background: #fff; }

.white {
  background: #fff;
  color: #666; }
  .white svg, .white .icon {
    fill: #666; }

.white2 {
  background: #fff;
  color: #767676; }
  .white2 svg, .white2 .icon {
    fill: #666; }

.white-border {
  background: #fff;
  color: #666;
  box-shadow: inset 0 0 1px 1px #e5e5e5; }
  .white-border svg, .white-border .icon {
    fill: #666; }

.radius0 {
  border-radius: 0px; }

.radius2 {
  border-radius: 2px; }

.radius4 {
  border-radius: 4px; }

.radius6 {
  border-radius: 6px; }

.radius8 {
  border-radius: 8px; }

.radius10 {
  border-radius: 10px; }

.radius12 {
  border-radius: 12px; }

.radius14 {
  border-radius: 14px; }

.radius16 {
  border-radius: 16px; }

.radius18 {
  border-radius: 18px; }

.radius20 {
  border-radius: 20px; }

.radius22 {
  border-radius: 22px; }

.radius24 {
  border-radius: 24px; }

.radius26 {
  border-radius: 26px; }

.radius28 {
  border-radius: 28px; }

.radius30 {
  border-radius: 30px; }

.radius32 {
  border-radius: 32px; }

.radius34 {
  border-radius: 34px; }

.radius36 {
  border-radius: 36px; }

.radius38 {
  border-radius: 38px; }

.radius40 {
  border-radius: 40px; }

.radius42 {
  border-radius: 42px; }

.radius44 {
  border-radius: 44px; }

.radius46 {
  border-radius: 46px; }

.radius48 {
  border-radius: 48px; }

.radius50 {
  border-radius: 50px; }

.radius52 {
  border-radius: 52px; }

.radius54 {
  border-radius: 54px; }

.radius56 {
  border-radius: 56px; }

.radius58 {
  border-radius: 58px; }

.radius60 {
  border-radius: 60px; }

.radius62 {
  border-radius: 62px; }

.radius64 {
  border-radius: 64px; }

.radius66 {
  border-radius: 66px; }

.radius68 {
  border-radius: 68px; }

.radius70 {
  border-radius: 70px; }

.radius72 {
  border-radius: 72px; }

.radius74 {
  border-radius: 74px; }

.radius76 {
  border-radius: 76px; }

.radius78 {
  border-radius: 78px; }

.radius80 {
  border-radius: 80px; }
