@charset "UTF-8";
//.font-regular {
//    font-family: "SourceHanSansSC-Regular", "Microsoft Yahei", "微软雅黑", sans-serif;
//    font-weight: normal;
//    font-style: normal;
//}
//
//.font-medium {
//    font-family: "SourceHanSansCN-Medium", "SourceHanSansSC-Medium", "Microsoft Yahei", "微软雅黑", sans-serif;
//    font-weight: normal;
//    font-style: normal;
//}
//
//.font-bold {
//    font-family: "SourceHanSansSC-Bold", "Microsoft Yahei", "微软雅黑", sans-serif;
//    font-weight: normal;
//    font-style: normal;
//}
.font-regular {
    font-weight: normal;
    font-style: normal;
}

.font-medium {
    font-weight: normal;
    font-style: normal;
}

.font-bold {
    font-weight: bold;
    font-style: normal;
}
