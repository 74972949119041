@charset "UTF-8";
.cl:after {
  content: "";
  display: block;
  clear: both; }

.ani {
  transition: all 0.5s ease; }

.font {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important; }

.oneline {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis; }

.svgGray {
  fill: #767676; }

.svgRed {
  fill: #e60023; }

.font-regular {
  font-weight: normal;
  font-style: normal; }

.font-medium, .info {
  font-weight: normal;
  font-style: normal; }

.font-bold {
  font-weight: bold;
  font-style: normal; }

.img404 {
  width: 352px;
  height: 140px;
  background-size: cover;
  margin-top: 102px; }

.page {
  flex-direction: column; }

.info {
  font-size: 30px;
  color: #333333;
  line-height: 30px;
  margin-top: 36px;
  padding-left: 26px;
  margin-bottom: 40px; }

.wrapper {
  justify-self: center;
  align-self: center;
  height: 100vh;
  padding: 0; }

.btn {
  flex-basis: 350px;
  height: 72px;
  background: #E60023;
  border-radius: 33px;
  font-size: 32px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 48px;
  letter-spacing: 1px;
  border: 0 none;
  outline: 0 none; }

.qrWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .qrWrapper .qrcode {
    margin-top: 36px;
    width: 200px;
    height: 200px; }
  .qrWrapper .help {
    font-size: 24px;
    font-weight: 500;
    color: #767676;
    line-height: 40px;
    margin-top: 8px;
    text-align: center; }

.searchInputWrapper {
  padding: 22px 30px;
  background: #fff;
  display: flex;
  flex-direction: column; }
  .searchInputWrapper .inputWrapper {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    display: flex; }
  .searchInputWrapper .pinliteLogo {
    width: 56px;
    height: 56px; }
  .searchInputWrapper .inputBox {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 614px;
    height: 72px;
    padding: 0 72px;
    background: #FFFFFF;
    border-radius: 36px;
    border: 3px solid #E5E5E5; }
    .searchInputWrapper .inputBox:before {
      content: " ";
      display: block;
      width: 36px;
      height: 36px;
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translate3d(0, -50%, 1PX);
      background: url("../../../assets/images/icon_pin_search.svg") no-repeat center center;
      background-size: 36px 36px; }
    .searchInputWrapper .inputBox .input {
      font-size: 30px;
      font-weight: 500;
      color: #333333;
      line-height: 45px;
      flex: 1; }
      .searchInputWrapper .inputBox .input::-webkit-input-placeholder {
        color: #AAAAAA; }
    .searchInputWrapper .inputBox .btnClear {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 72px;
      height: 72px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%); }
      .searchInputWrapper .inputBox .btnClear svg {
        fill: #767676;
        width: 32px;
        height: 32px; }
